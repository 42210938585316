header{
  @include box-shadow(5px,0px,29px,rgba(color(blackColor),0.2));
  .navbar{
    background: url('./../../../../../assets/images/login-bg.jpg');
    .navbar-brand{
      img{
        max-width: 80px;
      }
    }
  }
}