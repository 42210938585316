.sidebar{
  display: none;
  width: 100%;
  background: color(primary-color);
  padding: 10px 0px;
  position: relative;
  transition: all linear 0.3s;
  min-height: 50px;
  margin-bottom: 30px;
  &-user{
    text-align: center;
    margin-bottom: 30px;
    &-image{
      width:110px;
      height:110px;
      background: color(whitColor);
      @include border-radius(50%);
      margin: 10px auto;
      overflow: hidden;
      img{
        max-width: 100%;
      }
    }
    h4{
      color: color(whitColor);
      font-weight: bold;
      span{
        display: block;
        font-weight: 600;
        font-size: 15px;
        margin-top:8px;
      }
    }
  }
  &-links{
    li{
      a{
        color:color(whitColor);
        padding:10px 20px;
        display: block;
        text-decoration: none;
        position: relative;
        font-weight: 600;
        font-size: 15px;
        z-index: 9;
      }
      &.active{
        a{
          &:after{
            content:"";
            width: 98%;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            background-color: color(whitColor);
            height: 100%;
            position: absolute;
            right:0;
            z-index: -1;
            top:0;
          }
          color:color(blackColor);
        }
      }
    }
  }
  &-toggle{
    position: absolute;
    top:12px;
    left:20px;
    color: color(whitColor);
    cursor: pointer;
    @include media-breakpoint-up(lg) { 
      display: none;
    }
  }
  &-content{
    transition: all linear 0.3s;
    @include media-breakpoint-down(md){
      max-height: 0px;
    
    }
  }
  &.hidden{overflow: hidden;
    .sidebar-content{
      position: relative;
      top: 51px;
    }
  }
  &.open{
    // @include media-breakpoint-down(lg){
    //   max-height: 1000px;
    // }
      .sidebar-content{
        display: block;
        position: relative;
        background: color(primary-color);
        width: 100%;
        max-height: 2000px;
       
        z-index: 99;
        top:51px;
      }
    .sidebar-links {
      li{
        text-align: center;
        a{
          
          &:after{
            width: 100%;
            @include border-radius(0);
            
          }
        }
      }
        
      }
  }

  @include media-breakpoint-up(md){
    margin-bottom: 30px;
  }
  
  @include media-breakpoint-up(lg) { 
    max-width: 235px;
    position: fixed;
    top:0;
    width: 100%;
    height: 100% !important;
    max-height: 100% !important;
    overflow-y: auto !important;
  }

}


.sidenav---sidenav---_2tBP{
  background: color(primary-color) !important;
  position: fixed !important;
}

.sidenav---sidenav-toggle---1KRjR > span{
  opacity: 0 !important;
}
.sidenav---sidenav-toggle---1KRjR{
  float: right !important;
}
.sidenav---sidenav-toggle---1KRjR:after{
  content:"";
  width:26px;
  height: 26px;
  background:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiBjbGFzcz0iIj48Zz48Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGQ9Ik00NzkuMTgsOTEuODk3SDMyLjgyMUMxNC42OSw5MS44OTcsMCw3Ny4yMDcsMCw1OS4wNzdzMTQuNjktMzIuODIxLDMyLjgyMS0zMi44MjFINDc5LjE4ICAgYzE4LjEzLDAsMzIuODIsMTQuNjksMzIuODIsMzIuODIxUzQ5Ny4zMSw5MS44OTcsNDc5LjE4LDkxLjg5N3oiIGRhdGEtb3JpZ2luYWw9IiNGRjQ4NUEiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiNGRjQ4NUEiPjwvcGF0aD4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGQ9Ik0yOTUuMzg1LDI4OC44MjFIMzIuODIxQzE0LjY5LDI4OC44MjEsMCwyNzQuMTMsMCwyNTZzMTQuNjktMzIuODIxLDMyLjgyMS0zMi44MjFoMjYyLjU2NCAgIGMxOC4xMywwLDMyLjgyMSwxNC42OSwzMi44MjEsMzIuODIxUzMxMy41MTUsMjg4LjgyMSwyOTUuMzg1LDI4OC44MjF6IiBkYXRhLW9yaWdpbmFsPSIjRkY0ODVBIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjRkY0ODVBIj48L3BhdGg+CjwvZz48cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGIiBkPSJNNDc5LjE4LDI4OC44MjFoLTUyLjUxM2MtMTguMTMsMC0zMi44MjEtMTQuNjktMzIuODIxLTMyLjgyMXMxNC42OS0zMi44MjEsMzIuODIxLTMyLjgyMWg1Mi41MTMgIGMxOC4xMywwLDMyLjgyLDE0LjY5LDMyLjgyLDMyLjgyMVM0OTcuMzEsMjg4LjgyMSw0NzkuMTgsMjg4LjgyMXoiIGRhdGEtb3JpZ2luYWw9IiNGRkJCQzAiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRkZCQkMwIj48L3BhdGg+PHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRiIgZD0iTTQ3OS4xOCw0ODUuNzQ0SDMyLjgyMUMxNC42OSw0ODUuNzQ0LDAsNDcxLjA1MywwLDQ1Mi45MjNjMC0xOC4xMywxNC42OS0zMi44MjEsMzIuODIxLTMyLjgyMSAgSDQ3OS4xOGMxOC4xMywwLDMyLjgyLDE0LjY5LDMyLjgyLDMyLjgyMUM1MTIsNDcxLjA1Myw0OTcuMzEsNDg1Ljc0NCw0NzkuMTgsNDg1Ljc0NHoiIGRhdGEtb3JpZ2luYWw9IiNGRjQ4NUEiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiNGRjQ4NUEiPjwvcGF0aD48L2c+IDwvc3ZnPg==);
  position: absolute;
  background-size: cover;
  top:18px;
  right:15px;
}