.homepage{
    &-banner{
        display: flex;
        @include media-breakpoint-down(md){
            display: block;
          
        }
        &-left{
            width: 100%;
            padding:100px 0;
            position: relative;
            z-index: 99;
            flex-grow: 1;
            img{
                width: 100%;
            }
            
        }
        &-right{
           position: relative;
            padding:150px 5% 100px 5%;
            width: 100%;
            &:after{
                content:"";
                position: absolute;
                width: 140%;
                height: 100%;
                background: url(../../../../../assets/images/homepage-banner-right.jpg);
                background-size: cover;
                top:0;
                right: 0;
                z-index: -1;
            }
            h2{
                color: color(whitColor);
                font-size: 66px;
                font-weight: bold;
                span{
                    display: block;
                    font-size: 40px;
                    font-weight: normal;
                }
            }
            h5{
                color: color(whitColor);
                margin: 15px 0;
            }
            .btn-link{
                color: color(whitColor);
                text-decoration: none;
                border: 2px solid color(whitColor);
                margin-top: 20px;
            }
            .form-control{
                padding: 8px !important;
                height: 50px;
                border-radius: 6px !important;
            }
        }
        p{
            color: color(whitColor);
        }
       
    }

    .homepage-content{
        padding: 80px 0;
        h3{
            font-size: 45px;
            font-weight: bold;
            margin-bottom: 25px;
            color: color(blue-color);
            span{
                display: block;
                font-size: 32px;
                color: color(blackColor);
            }
        }
        p{
            font-size: 22px;
            color: color(blackColor);
            line-height: 40px;
        }
        .important-box{
            @include box-shadow(5px,0px,19px,rgba(color(blackColor),0.2));
            padding: 25px;
            h4{
                color: color(primary-color);
                font-weight: bold;
                margin-bottom: 20px;
                
            }
            ul{
                li{
                    margin: 0px 0 15px;
                    color: shadecolor(greyColor, dark);
                }
            }
        }
    }
}