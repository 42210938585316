@import 'sidebar';

header{
  transition: padding linear 0.1s;
  background: color(whitColor);
  margin-bottom: 30px;
  // @include media-breakpoint-up(lg) { 
  //   padding-left: 235px;
  // }
}

.dashboard{
  &-heading{
    margin-bottom: 30px;
    padding-left: 65px;
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // flex-direction: column;
    h4{
      font-weight: bold;
      margin: 0;
    }
    .events-category{
      max-width: 250px;
      width: 100%;
      position: relative;
      z-index: 9;
      margin-top: 8px;
    }
    @include media-breakpoint-up(md){
      flex-direction: row;
    }
  }
  // @include media-breakpoint-up(lg) { 
  //   padding:30px 30px 30px 260px;
  // }
  &-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    @include media-breakpoint-up(md){
      flex-direction: row;
    }
    &-right{
      @include media-breakpoint-up(md){
        width: calc(100% - 380px);
      }
     width: 100%;
      .slider-outer{
        padding:20px;
        padding-top:0;
        // background:color(whitColor);
        margin-bottom: 0px;
        @include border-radius(8px);
        // @include box-shadow(3px,0px,19px,rgba(color(blackColor),0.1));4
        .owl-item{
          padding:15px;
          background-color: color(whitColor);
          margin-bottom: 30px;
          @include border-radius(4px);
          @include box-shadow(3px,0px,19px,rgba(color(blackColor),0.1));
        }
        h5{
          margin-top: 20px;
          font-size: 22px;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 10px;
        }
        h3{
          margin-bottom: 20px;
          font-size: 26px;
          font-weight: bold;
        }
        p{
          font-size: .95rem;
          margin-bottom: 8px;
          color: color(black-300);
          span{
            font-size: 17px;
            margin-right: 8px;
            color: shadecolor(greyColor,dark);
          }
        }
        &.all-events{
          background: transparent;
          box-shadow: none;
          margin-top:0px;
          .event{
            padding:15px;
            background-color: color(whitColor);
            margin-bottom: 30px;
            @include border-radius(4px);
            @include box-shadow(3px,0px,19px,rgba(color(blackColor),0.1));
          }
        }
      }
      .btn-link{
        padding: 0;
        margin-top: 15px;
        min-height: 10px;
        position: relative;
        
        &::after{
          content:"";
          position: absolute;
          width: 22px;
          height: 22px;
          background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik01MDYuMTM0LDI0MS44NDNjLTAuMDA2LTAuMDA2LTAuMDExLTAuMDEzLTAuMDE4LTAuMDE5bC0xMDQuNTA0LTEwNGMtNy44MjktNy43OTEtMjAuNDkyLTcuNzYyLTI4LjI4NSwwLjA2OCAgICBjLTcuNzkyLDcuODI5LTcuNzYyLDIwLjQ5MiwwLjA2NywyOC4yODRMNDQzLjU1OCwyMzZIMjBjLTExLjA0NiwwLTIwLDguOTU0LTIwLDIwYzAsMTEuMDQ2LDguOTU0LDIwLDIwLDIwaDQyMy41NTcgICAgbC03MC4xNjIsNjkuODI0Yy03LjgyOSw3Ljc5Mi03Ljg1OSwyMC40NTUtMC4wNjcsMjguMjg0YzcuNzkzLDcuODMxLDIwLjQ1Nyw3Ljg1OCwyOC4yODUsMC4wNjhsMTA0LjUwNC0xMDQgICAgYzAuMDA2LTAuMDA2LDAuMDExLTAuMDEzLDAuMDE4LTAuMDE5QzUxMy45NjgsMjYyLjMzOSw1MTMuOTQzLDI0OS42MzUsNTA2LjEzNCwyNDEuODQzeiIgZmlsbD0iIzAwMDAwMCIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
          background-size: cover;
          top: 3px;
          opacity: 0;
          right: 0;
          transition: all ease 0.2s;
        }
        &:hover{
          text-decoration: none;
          color: color(blackColor);
          &:after{
            right: -30px;
            opacity: 1;
          }
        }
      }
      .image-outer{
        @include media-breakpoint-up(lg){
        max-height: 200px;
        overflow: hidden;
        min-height: 200px;
        
        }
        img{
          max-width: 100% ;
          @include media-breakpoint-up(lg) { 
            max-width: 100% ;
            //width: 150% !important;
            height:200px;
            width:100%;
          }
        }
      }
      .event-des{
        @include media-breakpoint-up(lg){
        min-height: 150px;
        }
      }
    }
    &-left{
      
      width: 100%;
      @include media-breakpoint-up(md){
        max-width: 350px;
      }
      .calender-outer{
        padding:20px;
        @include border-radius(4px);
        @include box-shadow(3px,0px,19px,rgba(color(blackColor),0.1));
        background-color: color(whitColor);
        margin-bottom: 30px;
        .react-calendar{
          border: none;
          .react-calendar__navigation__label{
            font-size: 15px;
            font-weight: 600;
          }
        }
        h6{
          margin:0 0 20px 0;
          font-weight: bold;
        }
      }
      .events-category{
        padding:20px;
        background-color: color(whitColor);
        @include border-radius(4px);
        @include box-shadow(3px,0px,19px,rgba(color(blackColor),0.1));
        margin-bottom: 50px;
        h6{
          margin:0 0 5px 0;
          font-weight: bold;
        }
        .custom-checkbox{
          margin-bottom: 15px;
        }
      }
    }
  }

  transition: padding linear 0.1s;
  &-white-outer{
    background-color: color(whitColor);
    padding: 30px;
    @include box-shadow(0px,0px,4px,rgba(color(blackColor),0.1));
    .form-group{
      margin-bottom: 1.5rem;
    }
  }
}
// .user-dashboard{
//   @include media-breakpoint-down(lg) { 
//     padding-left: 65px;
//   }
//   &.sidebar-open{
//     .dashboard{
//       @include media-breakpoint-up(lg) { 
//         padding:30px 30px 30px 260px;
//       }
//     }
//     header{
//       @include media-breakpoint-up(lg) { 
//         padding-left: 235px;
//       }
//     }
//   }
//   &.sidebar-collapse{
//     .dashboard{
//     @include media-breakpoint-up(lg) { 
//       padding:30px 30px 30px 80px;
//     }
//   }
//   // header{
//   //   @include media-breakpoint-up(lg) { 
//   //     padding-left: 80px;
//   //   }
//   // }
//   }
// }


.section-icon{
  width: 50px;
  height: 50px;
  background: color(whitColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: color(primary-color);
  @include box-shadow(0px,0px,6px,rgba(color(blackColor),0.2));
  position: absolute;
  left:0;
}