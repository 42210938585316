@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: 1200px,
  xxl: 1440px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1400px
);  


@import '~bootstrap/scss/bootstrap.scss';


