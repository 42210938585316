.recaptcha > div {
  float:right;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  li{
    list-style-type: none;
  }
}
.user-dashboard{
  min-height: 100vh;
  background-color: shadecolor(greyColor,light-grey) ;
}

.error{
  color: color(redColor);
  font-size: 13px;
}

.loader_div {
  display   : block;
  width     : 100%;
  height    : 100%;
  background: #fff;
  position  : fixed;
  z-index   : 999;
  opacity   : 0.6;
  left      : 0;

  .inner-loader {
    position     : absolute;
    top          : 50%;
    left         : 50%;
    /* background: #fff; */
    opacity      : 1;
    z-index      : 10;
  }
}
.no-padding{
padding: 0px;
}
