.event-popup{
    &.large{
        max-width: 845px;
    }
    .modal-header{
        border:none;
        background-color: color(blue-color);
        color:color(whitColor);
    }
    .close{
        color:color(whitColor);
        opacity: 1;
    }
}

.custom-popup{
.pop-event-detail{
    display: flex;
    color:color(primary-color);
    align-items: baseline;
    padding:10px;
    margin-bottom: 15px;
    // &:not(:last-child){
    // border-bottom: 1px solid shadecolor(greyColor, light);
    // }
    label{
        font-size: 14px;
        font-weight: bold;
        color:color(primary-color);
        margin:0 10px;
      }
      span.data{
        color:color(blackColor);
        font-size: 14px;
      }
     
}
.form-group{
    margin-bottom: 30px;
}
}